import { differenceInMilliseconds } from 'date-fns'
import { type EpisodeCurrentProgrammeFragment } from '#graphql-operations'
import { useState } from '#app'

export default function () {
  const time = useTime()

  const upcomingEpisodes: Ref<EpisodeCurrentProgrammeFragment[]> =
  computed(() => {
    const currentProgramme: Ref<EpisodeCurrentProgrammeFragment[]> =
      useState('currentProgramme')
    if (
      currentProgramme.value === undefined ||
      !currentProgramme.value.length ||
      time === undefined
    ) {
      return []
    }
    return currentProgramme.value.filter((item) => {
      return (
        differenceInMilliseconds(`${item.date?.end}Z`, time.value) > 0
      )
    })
  })

  const pastEpisodes: Ref<EpisodeCurrentProgrammeFragment[]> =
  computed(() => {
    const currentProgramme: Ref<EpisodeCurrentProgrammeFragment[]> =
      useState('currentProgramme')
    if (
      currentProgramme.value === undefined ||
      !currentProgramme.value.length ||
      time === undefined
    ) {
      return []
    }
    return currentProgramme.value.filter((item) => {
      return (
        differenceInMilliseconds(`${item.date?.end}Z`, time.value) <= 0
      )
    }).reverse()
  })

  const currentEpisode: Ref<EpisodeCurrentProgrammeFragment | undefined> =
    computed(() => {
      const currentProgramme: Ref<EpisodeCurrentProgrammeFragment[]> =
        useState('currentProgramme')
      if (
        currentProgramme.value !== undefined &&
        currentProgramme.value.length &&
        time !== undefined
      ) {
        return currentProgramme.value.find((item) => {
          if (typeof item?.date?.end !== 'string') {
            return false
          }
          return (
            differenceInMilliseconds(`${item.date?.end}Z`, time.value) > 0
          )
        })
      }
    })

  return {
    upcomingEpisodes,
    pastEpisodes,
    currentEpisode
  }
}
