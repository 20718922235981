import { startOfMinute, addMinutes, differenceInMilliseconds } from 'date-fns'

export default function useTime (): Ref<Date|undefined> {
  const timeState = useState<Date|undefined>('time')
  if (!timeState.value) { updateTime() }
  return timeState
}

function updateTime () {
  const now = new Date()
  const time = startOfMinute(now)
  const nextMinute = startOfMinute(addMinutes(now, 1))
  const timeout = differenceInMilliseconds(nextMinute, now)

  const timeState = useState('time')
  timeState.value = time

  setTimeout(updateTime, timeout)
}
